import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Flex, Heading } from 'rebass/styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { exportAllClientsAsCsvString, getClients } from '../../models/Clients'
import { setList, resetList } from '../../system/redux/reducers/list'
import Loading from '../../components/general/Loading'
import ClientCard from '../../components/cards/ClientCard'
import QuickSearchBar from '../../components/searchBar/QuickSearchBar'
import { FEATURE_FLAGS } from '../../helpers/FeatureFlags'

const ExportAllClientsButton = () => {
    const BUTTON_STATES = {
        DEFAULT: 'default',
        LOADING: 'loading',
        SUCCESS: 'success',
        ERROR: 'error',
    }
    
    const [state, setState] = useState(BUTTON_STATES.DEFAULT)

    const buttonText = {
        [BUTTON_STATES.DEFAULT]: 'Export All',
        [BUTTON_STATES.LOADING]: 'Exporting...',
        [BUTTON_STATES.SUCCESS]: 'Exported!',
        [BUTTON_STATES.ERROR]: 'Error',
    }

    const buttonColor = {
        [BUTTON_STATES.DEFAULT]: 'red',
        [BUTTON_STATES.LOADING]: 'greyMedium',
        [BUTTON_STATES.SUCCESS]: 'green',
        [BUTTON_STATES.ERROR]: 'red',
    }

    const flashResult = async (state) => {
        setState(state)
        await new Promise(resolve => setTimeout(resolve, 2000))
        setState(BUTTON_STATES.DEFAULT)
    }

    const handleClick = async () => {
        if (state !== BUTTON_STATES.DEFAULT) return

        setState(BUTTON_STATES.LOADING)
        
        const clients = await exportAllClientsAsCsvString()

        if (!clients) {
            return flashResult(BUTTON_STATES.ERROR)
        }

        // convert this to a blob and download it
        const blob = new Blob([clients], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `clients.${new Date().toISOString().split('T')[0]}.csv`
        document.body.appendChild(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(url)

        return flashResult(BUTTON_STATES.SUCCESS)
    }

    return (
        <Button onClick={handleClick} variant='primarySmall' bg={buttonColor[state]} sx={state !== BUTTON_STATES.DEFAULT ? { cursor: "not-allowed", ":hover": { bg: buttonColor[state] }} : null}>{buttonText[state]}</Button>
    )
}

const ClientsView = ({ dispatch, PageInfo, list, lastVisible, filter, term, listPage }) => {
    const [loading, setLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)

    const fetchMoreData = async ({ page = 0, term, first = false, filter }) => {
        setLoadMore(true)
        const perPage = 25
        let items = [...list]
        let last = lastVisible
        
        if (first) {
            items = []
            last = false
        }

        const results = await getClients({ page, limitNumber: perPage, lastVisible: last, filter, term })

        if (results.list) {
            Object.entries(results.list).map(([key, item]) => {
                items.push(item)
            })
            const listData = JSON.stringify({
                list: items,
                lastVisible: results.lastVisible,
                filter: results.filter,
                page,
                term
            })
            dispatch(setList(listData))
            setLoading(false)
        }
        setLoadMore(false)
    }

    useEffect(() => {
        setLoading(true)
        dispatch(resetList())
        fetchMoreData({ first: true })
    }, [])

    useEffect(() => {
        if (!lastVisible && filter && !filter.length) {
            fetchMoreData({ first: true })
        }
    }, [lastVisible])

    return (
        <Box variant="card" pt="lg" mt="sm">
            <Box mb='md' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Flex px="sm" pb="sm" alignItems="center" justifyContent="space-between">
                    <Heading as="h2" variant="h4" color="greyMedium">{PageInfo.page}</Heading>
                    <Flex alignItems="start" justifyContent="start">
                        { FEATURE_FLAGS.ENABLE_CLIENTS_EXPORT && <ExportAllClientsButton /> }
                    </Flex>
                </Flex>
                <Box mb="xs">
                    <QuickSearchBar search={fetchMoreData} source={process.env.GATSBY_ALGOLIA_CLIENT_INDICE} />
                </Box>
            </Box>
            {
                loading ?
                    <Box p='xs'><Loading /></Box> :
                    <InfiniteScroll
                        style={{ overflow: 'initial' }}
                        dataLength={list.length}
                        next={() => fetchMoreData({ page: listPage + 1, term })}
                        hasMore={(filter?.length) ? false : true}
                        loader={loadMore ? <Box p='xs'><Loading /></Box> : null}
                    >
                        <Box px="xs" pb='xs' sx={{
                            display: 'grid',
                            gridGap: 'xs',
                            gridTemplateColumns: ['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)'],
                        }}>
                            {
                                Object.entries(list).map(([key, item]) => {
                                    return <ClientCard options={item} key={'list_' + key} />
                                })
                            }
                        </Box>
                    </InfiniteScroll>
            }
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        list: state.List.list,
        listPage: state.List.page,
        lastVisible: state.List.lastVisible,
        filter: state.List.filter,
        term: state.List.term,
    }
}
export default connect(mapStateToProps)(ClientsView)
