import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { Box, Flex, Text } from 'rebass/styled-components'
import Item from '../../components/rightSideBar/Item'
import ClientIdentityField from '../../components/forms/ClientIdentityField'
import DateSelect from '../../components/selects/DateSelect'
import StarRatingSelect from '../../components/selects/StarRatingSelect'
import CountrySelect from '../../components/selects/CountrySelect'
import { saveToggle } from '../../system/redux/reducers/savehandling'
import { setPageData } from '../../system/redux/reducers/page'
import { Checkbox } from '@rebass/forms'
import { ThemeContext } from 'styled-components'

const IdentityTab = ({ dispatch, data }) => {
    const theme = useContext(ThemeContext);
    const [clientData, setClientData] = useState({})
    const { register, reset, formState: { errors }} = useFormContext() // retrieve all hook methods
    const onInputChange = (name, event) => {
        const detailsData = { [name]: event.target.value }
        const submitData = JSON.stringify({ ...clientData, ...detailsData })
        dispatch(setPageData(submitData))
        dispatch(saveToggle(true))
    }
    const convertDateString = (date) => {
        return date ? { seconds: new Date(date).getTime() / 1000, nanoseconds: 0 } : null
    }
    const onAlternateChange = (key, value) => {
        const submitData = JSON.stringify({ ...clientData, [key]: value })
        dispatch(setPageData(submitData))
        dispatch(saveToggle(true))
    }
    const onContactChange = (key, event) => {
        const contactObj = { ...clientData.contact }
        if (key === 'telephone') {
            contactObj[key] = [event.target.value]
        } else {
            contactObj[key] = event.target.value
        }
        const detailsData = { contact: contactObj }
        const submitData = JSON.stringify({ ...clientData, ...detailsData })
        dispatch(setPageData(submitData))
        dispatch(saveToggle(true))
    }
    const toggleContactRule = (key) => {
        const contactRules = clientData?.contact?.rules ? { ...clientData.contact.rules } : {}
        contactRules[key] = !contactRules[key]

        const contactObj = { ...clientData.contact, rules: contactRules }
        const submitData = JSON.stringify({ ...clientData, contact: contactObj })
        dispatch(setPageData(submitData))
        dispatch(saveToggle(true))
    }
    const onAssistantChange = (key, event) => {
        let assistantArray = []
        if (clientData.assistant) {
            assistantArray = [ ...clientData.assistant ]
            assistantArray[0] = { ...assistantArray[0] }
            assistantArray[0][key] = event.target.value
        } else {
            assistantArray.push({ [key]: event.target.value })
        }

        const detailsData = { assistant: assistantArray }
        const submitData = JSON.stringify({ ...clientData, ...detailsData })
        dispatch(setPageData(submitData))
        dispatch(saveToggle(true))
    }
    const onAddressChange = (key, event) => {
        let addressArray = []
        if (clientData.addresses) {
            addressArray = [ ...clientData.addresses ]
            addressArray[0] = { ...addressArray[0] }
            addressArray[0][key] = event.target.value
        } else {
            addressArray.push({ [key]: event.target.value })
        }

        const detailsData = { addresses: addressArray }
        const submitData = JSON.stringify({ ...clientData, ...detailsData })
        dispatch(setPageData(submitData))
        dispatch(saveToggle(true))
    }

    useEffect(() => {
        if (data) {
            // convert date_birth to date object
            const clientData = { 
                ...data,
                date_birth: data?.date_birth?.seconds ? new Date(data.date_birth.seconds * 1000) : null
            }

            setClientData(clientData)
            reset({ clientData }, { keepErrors: true })
        }
    }, [data, reset])
    
    return (
        <Box fontSize='md'>
            <Item height={errors && errors.title ? '70px' : 'auto'} border={true} title="Title">
                <ClientIdentityField
                    name='title'
                    placeholder='Eg. Mr, Mrs, Sir etc...'
                    {...register('title', { required: false })}
                    type='text'
                    variant="contentEditableInput"
                    onChange={(e) => onInputChange('title', e)}
                    defaultValue={clientData.title || null}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.title ? errors.title.message :null}
                />
            </Item>
            <Item height={errors && errors.first_name ? '70px' : 'auto'} border={true} title="First Name*">
                <ClientIdentityField
                    name="first_name"
                    placeholder='Name'
                    {...register('first_name', { required: "Cannot be empty" })}
                    type='text'
                    variant="contentEditableInput"
                    onChange={(e) => onInputChange('first_name', e)}
                    defaultValue={clientData.first_name || null}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.first_name ? errors.first_name.message :null}
                />
            </Item>
            <Item height={errors && errors.last_name ? '70px' : 'auto'} border={true} title="Last Name*">
                <ClientIdentityField
                    name='last_name'
                    placeholder='Surname'
                    {...register('last_name', { required: "Cannot be empty" })}
                    type='text'
                    variant="contentEditableInput"
                    onChange={(e) => onInputChange('last_name', e)}
                    defaultValue={clientData.last_name || null}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.last_name ? errors.last_name.message :null}
                />
            </Item>
            <Item height={errors && errors.type ? '70px' : 'auto'} border={true} title="Contact Type">
                <ClientIdentityField
                    name="type"
                    placeholder='Eg. Supplier, Client etc...'
                    {...register('type', { required: false })}
                    type='text'
                    variant="contentEditableInput"
                    onChange={(e) => onInputChange('type', e)}
                    defaultValue={clientData.type || null}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.type ? errors.type.message :null}
                />
            </Item>
            <Item height={errors && errors.company ? '70px' : 'auto'} border={true} title="Company">
                <ClientIdentityField
                    name="company"
                    placeholder='Company'
                    {...register('company', { required: false })}
                    type='text'
                    defaultValue={clientData.company || null}
                    variant="contentEditableInput"
                    onChange={(e) => onInputChange('company', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.company ? errors.company.message :null}
                />
            </Item>
            <Item border={true} title="Date of Birth">
                <DateSelect onInputChange={(value) => onAlternateChange('date_birth', convertDateString(value))} selectedValue={clientData.date_birth} />
            </Item>
            <Item border={true} title="VIP Rating">
                <Box py='xxs'>
                    <StarRatingSelect onInputChange={(value) => onAlternateChange('rating', value)} rating={clientData.rating} />
                </Box>
            </Item>
            <Item border={true} title="Contact Rules">
                <Box py='xxs'>
                    <Flex ml={-1}>
                        <Flex alignItems='center' mr={4} sx={{ cursor: 'pointer' }} onClick={() => toggleContactRule('newsletter')}>
                            <Checkbox mr={1} checked={clientData?.contact?.rules?.newsletter || false} color={clientData?.contact?.rules?.newsletter ? theme.colors.red : theme.colors.greyLight}/>
                            <Text>Newsletter</Text>
                        </Flex>
                        <Flex alignItems='center' sx={{ cursor: 'pointer' }} onClick={() => toggleContactRule('brochure')}>
                            <Checkbox mr={1} checked={clientData?.contact?.rules?.brochure || false} color={clientData?.contact?.rules?.brochure ? theme.colors.red : theme.colors.greyLight}/>
                            <Text>Brochure</Text>
                        </Flex>
                    </Flex>
                </Box>
            </Item>
            <Item height={errors && errors.email ? '70px' : 'auto'} border={true} title="Email">
                <ClientIdentityField
                    name="email"
                    placeholder='eg. client@company.com'
                    {...register('email', { required: false, pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address"
                    }})}
                    type='text'
                    defaultValue={clientData.contact?.email || null}
                    variant="contentEditableInput"
                    onChange={(e) => onContactChange('email', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.email ? errors.email.message :null}
                />
            </Item>
            <Item height={errors && errors.instagram ? '70px' : 'auto'} border={true} title="Instagram">
                <ClientIdentityField
                    name="instagram"
                    placeholder='eg. https://www.instagram.com/instagramhandle'
                    {...register('instagram', { required: false })}
                    type='text'
                    defaultValue={clientData.contact?.instagram || null}
                    variant="contentEditableInput"
                    onChange={(e) => onContactChange('instagram', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.instagram ? errors.instagram.message :null}
                />
            </Item>
            <Item height={errors && errors.website ? '70px' : 'auto'} border={true} title="Website">
                <ClientIdentityField
                    name="website"
                    placeholder='Website URL eg. www.company.com or company.com'
                    {...register('website', { required: false, pattern: {
                        value: /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/i,
                        message: "Invalid website address"
                    }})}
                    type='text'
                    defaultValue={clientData.website || null}
                    variant="contentEditableInput"
                    onChange={(e) => onInputChange('website', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.website ? errors.website.message :null}
                />
            </Item>
            <Item height={errors && errors.mobile? '70px' : 'auto'} border={true} title="Phone (Mobile)">
                <ClientIdentityField
                    name="mobile"
                    placeholder='Mobile Number'
                    {...register('mobile', { required: false, pattern: {
                        value: /[0-9]/i,
                        message: "Invalid contact number"
                    }})}
                    type='text'
                    defaultValue={clientData.contact?.mobile || null}
                    variant="contentEditableInput"
                    onChange={(e) => onContactChange('mobile', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.mobile ? errors.mobile.message :null}
                />
            </Item>
            <Item height={errors && errors.telephone ? '70px' : 'auto'} border={true} title="Phone (Landline)">
                <ClientIdentityField
                    name="telephone"
                    placeholder='Landline Number'
                    {...register('telephone', { required: false, pattern: {
                        value: /[0-9]/i,
                        message: "Invalid contact number"
                    }})}
                    type='text'
                    defaultValue={(clientData.contact?.telephone && clientData.contact?.telephone[0]) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onContactChange('telephone', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.telephone ? errors.telephone.message :null}
                />
            </Item>
            <Item height={errors && errors.address_line_1 ? '70px' : 'auto'} border={true} title="Address">
                <ClientIdentityField
                    name="address_line_1"
                    placeholder='Address Line 1'
                    {...register('address_line_1', { required: false })}
                    type='text'
                    defaultValue={(clientData.addresses && clientData.addresses[0]?.address_line_1) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAddressChange('address_line_1', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.address_line_1 ? errors.address_line_1.message :null}
                />
            </Item>
            <Item border={true} title="Address Line 2">
                <ClientIdentityField
                    name="address_line_2"
                    placeholder='Address Line 2'
                    {...register('address_line_2', { required: false })}
                    type='text'
                    defaultValue={(clientData.addresses && clientData.addresses[0]?.address_line_2) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAddressChange('address_line_2', e)}
                    pl="0"
                />
            </Item>
            <Item border={true} title="Address Line 3">
                <ClientIdentityField
                    name="address_line_3"
                    placeholder='Address Line 3'
                    {...register('address_line_3', { required: false })}
                    type='text'
                    defaultValue={(clientData.addresses && clientData.addresses[0]?.address_line_3) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAddressChange('address_line_3', e)}
                    pl="0"
                />
            </Item>
            <Item height={errors && errors.town ? '70px' : 'auto'} border={true} title="Town">
                <ClientIdentityField
                    name="town"
                    placeholder='Town'
                    {...register('town', { required: false })}
                    type='text'
                    defaultValue={(clientData.addresses && clientData.addresses[0]?.town) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAddressChange('town', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.town ? errors.town.message :null}
                />
            </Item>
            <Item height={errors && errors.state ? '70px' : 'auto'} border={true} title="County/State/Province">
                <ClientIdentityField
                    name="state"
                    placeholder='County/State/Province'
                    {...register('state', { required: false })}
                    type='text'
                    defaultValue={(clientData.addresses && clientData.addresses[0]?.state) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAddressChange('state', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.state ? errors.state.message :null}
                />
            </Item>
            <Item height={errors && errors.postcode ? '70px' : 'auto'} border={true} title="Post/Zip Code">
                <ClientIdentityField
                    name="postcode"
                    placeholder='Post/Zip Code'
                    {...register('postcode', { required: false, })}
                    type='text'
                    defaultValue={(clientData.addresses && clientData.addresses[0]?.postcode) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAddressChange('postcode', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.postcode ? errors.postcode.message :null}
                />
            </Item>
            <Item border={true} title="Country">
                <CountrySelect selectedValue={clientData.addresses && clientData.addresses[0]?.country} onChange={(value) => onAddressChange( 'country', { target: { value }} )} />
            </Item>
            <Item height={errors && errors.assistant_name ? '70px' : 'auto'} border={true} title="Assistant Name">
                <ClientIdentityField
                    name="assistant_name"
                    placeholder='Assistant Name'
                    {...register('assistant_name', { required: false })}
                    type='text'
                    defaultValue={(clientData.assistant && clientData.assistant[0]?.name) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAssistantChange('name', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.assistant_name ? errors.assistant_name.message :null}
                />
            </Item>
            <Item height={errors && errors.assistant_phone ? '70px' : 'auto'} border={true} title="Assistant Phone">
                <ClientIdentityField
                    name="assistant_phone"
                    placeholder='Assistant Phone'
                    {...register('assistant_phone', { required: false, pattern: {
                        value: /[0-9]/i,
                        message: "Invalid contact number"
                    }})}
                    type='text'
                    defaultValue={(clientData.assistant && clientData.assistant[0]?.phone) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAssistantChange('phone', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.assistant_phone ? errors.assistant_phone.message :null}
                />
            </Item>
            <Item height={errors && errors.assistant_email ? '70px' : 'auto'} border={true} title="Assistant Email">
                <ClientIdentityField
                    name="assistant_email"
                    placeholder='Assistant Email'
                    {...register('assistant_email', { required: false, pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address"
                    }})}
                    type='text'
                    defaultValue={(clientData.assistant && clientData.assistant[0]?.email) || null}
                    variant="contentEditableInput"
                    onChange={(e) => onAssistantChange('email', e)}
                    pl="0"
                    errors={errors}
                    errorMessage={errors.assistant_email ? errors.assistant_email.message :null}
                />
            </Item>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        data: state.Page.data
    }
}
export default connect(mapStateToProps)(IdentityTab)
