import React from 'react'
import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import { ClientsSection } from '../system/settings/pageData'
import ClientsView from '../views/clients/index'
import ClientsFunctionsView from '../views/clients/functions'
import ClientsEditView from '../views/clients/modify'
import Pointer from '../views/pointer'

const PageInfo = {
    parent: ClientsSection.section,
    page: 'Add New',
    icon: ClientsSection.icon,
}

const PostRoutes = () => {
    return (
        <Router>
            <Pointer PageInfo={{ ...PageInfo, page: 'Functions' }} Component={ClientsFunctionsView} minimumRole={ClientsSection.minimumRole} path={withPrefix('/clients/functions')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Edit' }} Component={ClientsEditView} minimumRole={ClientsSection.minimumRole} path={withPrefix('/clients/edit/:id')} />
            <Pointer PageInfo={PageInfo} Title='Add New' Component={ClientsEditView} minimumRole={ClientsSection.minimumRole} path={withPrefix('/clients/add')} id='' />
            <Pointer PageInfo={{ ...PageInfo, page: 'All Clients' }} Component={ClientsView} minimumRole={ClientsSection.minimumRole} path={withPrefix('/clients')} />
        </Router>
    );
}

export default PostRoutes
