export const addOrUpdateClientToMailingList = async (data) => {
    data.listVars = {
        GATSBY_MAILING_LIST_CAMPAIGN_MONITOR_API_KEY:
            process.env.GATSBY_MAILING_LIST_CAMPAIGN_MONITOR_API_KEY ?? null,
        GATSBY_MAILING_LIST_CAMPAIGN_MONITOR_LIST_ID:
            process.env.GATSBY_MAILING_LIST_CAMPAIGN_MONITOR_LIST_ID ?? null,
    };

    if (typeof window !== "undefined") {
        const { firebaseFunctionCall } = await import(
            "../system/firebase/useFunctions"
        );
        return firebaseFunctionCall("addOrUpdateClientToMailingList")(data);
    }
    return null;
};
