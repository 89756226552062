import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass/styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import {
    saveToggle,
    savingToggle,
} from '../../system/redux/reducers/savehandling'
import PageLoader from '../../components/loaders/PageLoader'
import loadable from '@loadable/component'
import TabContainer from '../../components/tabs/TabContainer'
import IdentityTab from '../../modules/clients/IdentityTab'
import CollectionItemsTab from '../../modules/clients/CollectionItemsTab'
import NotesTab from '../../modules/NotesTab'
import OptionsTab from '../../modules/clients/OptionsTab'
import Loading from '../../components/general/Loading'
import ClientCard from '../../components/cards/ClientCard'
import { setPageData, addPageData } from '../../system/redux/reducers/page'
import { addClient, updateClient } from '../../models/Clients'
import { addAddress, updateAddress } from '../../models/Addresses'
import { isGrantedAccess } from '../../helpers/Permissions'
import { addOrUpdateClientToMailingList } from '../../helpers/MailingLists'

import {
    doc,
    onSnapshot,
    query,
    collection,
    where,
    orderBy,
} from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import LeadsTab from '../../modules/clients/LeadsTab'

const RightSideBar = loadable(() =>
    import('../../modules/clients/RightSideBar')
)

const ClientsModify = ({ dispatch, id, isSaving, data, permissionsTier }) => {
    const methods = useForm()
    const [tabData, setTabs] = useState([])

    useEffect(() => {
        let isCancelled = false
        if (id) {
            onSnapshot(doc(db, 'clients', id), (doc) => {
                if (!isCancelled) {
                    const docData = doc.data()
                    if (docData) {
                        docData.id = doc.id
                        dispatch(setPageData(JSON.stringify(docData)))
                    } else {
                        // Failsafe for if Client gets deleted
                        dispatch(setPageData(JSON.stringify({})))
                    }
                }
            })
        } else {
            dispatch(setPageData(JSON.stringify({})))
        }
        return () => {
            isCancelled = true
        }
    }, [id])

    useEffect(() => {
        let isCancelled = false
        if (id && data && (!data.addresses || !data.addresses.length)) {
            const q = query(
                collection(db, 'addresses'),
                where('clients', '==', id),
                orderBy('date_modified', 'desc')
            )
            onSnapshot(q, (snapshot) => {
                if (!isCancelled) {
                    const output = []
                    snapshot.forEach((doc) => {
                        const data = doc.data()
                        data.id = doc.id
                        output.push(data)
                    })

                    dispatch(addPageData(JSON.stringify({ addresses: output })))
                }
            })
        }
        return () => {
            isCancelled = true
        }
    }, [id, data])

    //Sets the Tabs
    useEffect(() => {
        if (permissionsTier) {
            const tabs = [
                {
                    title: 'Identity',
                    content: <IdentityTab />,
                },
                {
                    title: 'Notes',
                    content: <NotesTab type='client' />,
                },
                {
                    title: 'Vehicles',
                    content: <CollectionItemsTab />,
                },
            ]

            if (isGrantedAccess('areaLeads', permissionsTier)) {
                tabs.push({
                    title: 'Leads',
                    content: <LeadsTab />,
                })
            }

            if (Object.keys(data).length) {
                tabs.push({
                    title: 'Options',
                    content: <OptionsTab />,
                })
            }
            setTabs(tabs)
        }
    }, [data, permissionsTier])

    const onInputChange = (name, event) => {}

    const onSelectChange = (name, event) => {}

    const onSubmit = async () => {
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))

        //We're updating all post data
        // const data = store.getState()
        const updatedData = { ...data }
        const addresses = data.addresses

        if (data.id) {
            // This is an update
            const docID = data.id
            delete updatedData.id
            delete updatedData.date_added

            updateClient(docID, updatedData).then(() => {
                if (addresses && addresses.id) {
                    // This is an address update
                    const updatedAddressData = { ...addresses[0] }
                    delete updatedAddressData.id
                    delete updatedAddressData.date_added

                    updateAddress(addresses[0].id, updatedAddressData).then(
                        () => {
                            addOrUpdateClientToMailingList({ client: updatedData }).catch().finally(() => {
                                dispatch(savingToggle(false))
                            })
                        }
                    )
                } else if (addresses) {
                    // This is an address add
                    const updatedAddressData = { ...addresses[0] }
                    updatedAddressData.clients = docID

                    addAddress(updatedAddressData).then(() => {
                        addOrUpdateClientToMailingList({ client: updatedData }).catch().finally(() => {
                            dispatch(savingToggle(false))
                        })
                    })
                } else {
                    addOrUpdateClientToMailingList({ client: updatedData }).catch().finally(() => {
                        dispatch(savingToggle(false))
                    })
                }
            })
        } else {
            // This is an add
            addClient(updatedData).then((docRef) => {
                if (addresses) {
                    const updatedAddressData = { ...addresses[0] }
                    updatedAddressData.clients = docRef.data

                    addAddress(updatedAddressData).then(() => {
                        addOrUpdateClientToMailingList({ client: updatedData }).catch().finally(() => {
                            dispatch(savingToggle(false))
                            navigate(`/clients/edit/${docRef.data}`)
                        })
                    })
                } else {
                    addOrUpdateClientToMailingList({ client: updatedData }).catch().finally(() => {
                        dispatch(savingToggle(false))
                        navigate(`/clients/edit/${docRef.data}`)
                    })
                }
            })
        }
    }

    return !data ? (
        <Loading />
    ) : (
        <FormProvider {...methods}>
            <Box
                as='form'
                onSubmit={methods.handleSubmit(() => {
                    onSubmit()
                })}
            >
                <Flex>
                    <Flex flexGrow={1}>
                        <Flex
                            flexGrow={1}
                            flexDirection={[
                                'column',
                                'column',
                                'column',
                                'column',
                                'row',
                                'row%',
                                'row',
                            ]}
                        >
                            <Box
                                mt='sm'
                                mr='sm'
                                width={[
                                    '100%',
                                    '100%',
                                    '100%',
                                    '100%',
                                    '25%',
                                    '25%',
                                    '25%',
                                ]}
                            >
                                <ClientCard options={data} simplified={true} />
                            </Box>
                            <Flex
                                flexGrow={1}
                                flex={1}
                                pr='xs'
                                flexDirection='column'
                            >
                                <Box variant='card' mt='sm' p='xs'>
                                    <TabContainer
                                        data={tabData}
                                        addLeadButtons
                                    />
                                </Box>
                            </Flex>
                        </Flex>
                        <RightSideBar
                            onSelectChange={onSelectChange}
                            onInputChange={onInputChange}
                            section='clients'
                        />
                    </Flex>
                    {isSaving ? <PageLoader /> : null}
                </Flex>
            </Box>
        </FormProvider>
    )
}

function mapStateToProps(state) {
    return {
        isSaving: state.Savehandling.isSaving,
        data: state.Page.data,
        permissionsTier: state.User.permissionsTier,
    }
}
export default connect(mapStateToProps)(ClientsModify)
