import React from 'react'
import { Box, Heading } from 'rebass/styled-components'
import { updateClientTimestamp } from '../../models/Clients'
import { updateAddressBatch } from '../../models/Addresses'

const ClientsFunctionsView = ({ dispatch }) => {
    const sendUpdateClientTimestamp = () => {
        updateClientTimestamp()
    }

    return (
        <Box variant="card" pt="lg" mt="sm">
            <Box mb='md' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Box px='xs' pb='xs'>
                    <Heading as="h2" variant="h4" color="greyMedium">Clients DB Functions</Heading>
                </Box>
            </Box>

            <Box onClick={sendUpdateClientTimestamp}>updateClientTimestamp</Box>
            <Box onClick={updateAddressBatch}>updateAddressBatch</Box>
        </Box>
    )
}

export default ClientsFunctionsView
