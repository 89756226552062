import { db } from '../system/firebase/index'
import { getDocs, collection } from 'firebase/firestore'

export const addAddress = async (data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('addAddress')(data)
    }
    return null
}

export const addAddressesBatch = async (data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('addAddressesBatch')(data)
    }
    return null
}

export const updateAddress = async (id, data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        firebaseFunctionCall('updateAddress')({
            doc: id,
            data,
        })
    }
}

export const updateAddressBatch = async () => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')

        const latest = await getDocs(collection(db, 'addresses'))
        let count = 0
        const promiseArray = []
        let lists = []
        /* eslint-disable no-await-in-loop */
        for (const item of latest.docs) {
            count++
            const itemData = item.data()

            const clientObj = itemData.clients
            const clientId = Object.keys(clientObj)[0]
            itemData.clients = clientId

            lists.push({ doc: item.id, content: itemData })
            if (count === 300) {
                promiseArray.push(
                    firebaseFunctionCall('updateAddressesBatch')(lists),
                )
                count = 0
                lists = []
            }
            
        }
        /* eslint-disable no-await-in-loop */

        if (lists.length) {

            promiseArray.push(
                firebaseFunctionCall('updateAddressesBatch')(lists),
            )
        }

        Promise.all(promiseArray).then(() => {
            // console.log('address batch sent')
        })

        return true
    }
    return null
}
export const deleteAddress = async (doc) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')

        return firebaseFunctionCall('deleteAddress')(doc)
    }
}