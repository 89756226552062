import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Text, Link as ExternalLink } from 'rebass/styled-components'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKeyboard } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import StarRatingSelect from '../selects/StarRatingSelect'
import { getClientItems } from '../../models/Clients'
// import { getLeadsByClient } from '../../models/Leads'

const CardHeader = styled(Box)`
    border-top-left-radius: 4px 4px;
    border-top-right-radius: 4px 4px;
`
const CardContentBox = styled(Box)`
    border-bottom-left-radius: 4px 4px;
    border-bottom-right-radius: 4px 4px;
`
const TitleLink = styled(Link)`
    color: ${(props) => props.theme.colors.white};

    &:hover {
        color: ${(props) => props.theme.colors.red};
    }
`
const Cell = ({ title, value }) => {
    return (
        <Flex justifyContent='space-between' py='xxs'>
            <Text fontWeight='bold'>{title}:</Text>
            <Text textAlign='right'>{value}</Text>
        </Flex>
    )
}

const ClientCard = ({ options, simplified = false }) => {
    const [item, updateItem] = useState(false)
    const [itemCount, setItemCount] = useState({ vehicles:0, sold:0, bought:0 })
    // const [leadsCount, setLeadsCount] = useState(0)
    useEffect(() => {
        if (Object.keys(options).length) {
            updateItem(options)
        } else {
            updateItem(false)
        }
    }, [options])
    const noneSet = 'None Set'
    const name = !item ?  noneSet :  ( item.title || '' ) + ' ' + ( item.first_name || '' ) + ' ' + ( item.last_name || '' )
    let emailOutput = []
    let instaOutput = []
    if (item.contact && item.contact.email) {
        const emails = item.contact.email.split(';')

        emails.forEach((email) => {
            const theEmail = email.trim()
            emailOutput.push(<ExternalLink key={item.id + theEmail} fontWeight='regular' href={`mailto:${theEmail}`} title={`Click here to email ${theEmail}`}>{theEmail}; </ExternalLink>)
        })
    }
    if (item.contact && item.contact.instagram) {
        const instagram = item.contact.instagram
        const theInsta = instagram.replace('https://www.instagram.com/', '')
        instaOutput.push(<ExternalLink fontWeight='regular' href={instagram} title={`Click here to follow ${theInsta}`} target='_blank'>{theInsta}</ExternalLink>)
    }
    useEffect(() => {
        let isCancelled = false
        if (item.id) {
            getClientItems(item.id).then((value) => {
                if (!isCancelled) {
                    const count = { vehicles:value.docs.length, sold:0, bought:0 }
                    value.forEach((doc) => {
                        const item = doc.data()
                        if (item.current) {
                            count.bought = count.bought + 1
                        } else {
                            count.sold = count.sold + 1
                        }
                    })
                    setItemCount(count)
                }
            })
        }

        return () => {
            isCancelled = true
        }
    }, [item])

    // // Getting the leads
    // useEffect(() => {
    //     let isCancelled = false

    //     if(item.id) {
    //         const fetchData = async () => {
    //             const results = await getLeadsByClient(item.id, { limitNumber: 99 })
    //             if (!isCancelled) {
    //                 if (results.list) {
    //                     setLeadsCount(results.list.length)
    //                 }
    //             }
    //         }
    //         fetchData()
    //     }

    //     return () => {
    //         isCancelled = true
    //     }
    // }, [item])
   

    return (
        <Box variant={ simplified ? "clientCardSimplified" : "clientCard"}>
            {
                !simplified ?
                    <Flex pb='xxs' justifyContent='space-between'>
                        <Box fontWeight='bold'>
                            {item.company || <Box height='18px' width='10px'></Box>}
                        </Box>
                        <Box>
                            <Link color='greyMedium' title='Click to edit' to={`/clients/edit/${item.id}`}>
                                <FontAwesomeIcon icon={faKeyboard} />
                            </Link>
                        </Box>
                    </Flex>
                    : null
            }
           
            <CardHeader color='white' bg='greyMedium' p='xs'>
                <Box pb='xxs'>
                    <StarRatingSelect rating={item.rating} noCursor />
                </Box>
                <Box minHeight='22px' width='100%'>
                    {
                        simplified 
                            ? <Text variant='large'>{(name.trim() !== 'null' && name) || noneSet}</Text>
                            : (
                                <TitleLink color='white' to={`/clients/edit/${item.id}`}>
                                    <Text variant='large'>{(name.trim() !== 'null' && name) || noneSet}</Text>
                                </TitleLink>
                            )
                    }
                </Box>
            </CardHeader>
            <CardContentBox bg='white' p='xs' fontSize='md'>
                <Cell title='Company' value={item.company || noneSet} />
                <Cell title='Mobile tel' value={(item.contact && item.contact.mobile) || noneSet} />
                <Cell title='Phone tel' value={(item.contact && item.contact.telephone && item.contact.telephone[0]) || noneSet} />
                <Cell title='Email' value={(emailOutput.length && emailOutput) || noneSet } />
                <Cell title='Instagram' value={(instaOutput.length && instaOutput) || noneSet } />
                <Cell title='Assistant' value={(item.assistant && item.assistant[0]?.name) || noneSet} />
                <Cell title='Assistant tel' value={(item.assistant && item.assistant[0]?.phone) || noneSet} />
                {/* <Cell title='Vehicles' value={itemCount.vehicles} /> */}
                <Cell title='Vehicles Currently Owned' value={itemCount.bought} />
                <Cell title='Vehicles Previously Owned' value={itemCount.sold} />
                {/* <Cell title='Leads' value={leadsCount} /> */}
            </CardContentBox>
        </Box>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(ClientCard)
