import React, { useState, useEffect } from 'react'
import { Box, Heading, Text } from 'rebass/styled-components'
import { connect } from 'react-redux'
import {  getDocs, query, collection, where } from 'firebase/firestore'
import CollectionCard from '../../components/cards/CollectionCard'
import { db } from '../../system/firebase/index'
import { getCollectionList } from '../../helpers/ListHandler'

const CollectionItemsTab = ({ dispatch, clientId }) => {
    const [list, setList] = useState([])
    useEffect(() => {
        let isCancelled = false

        if (!clientId) {
            setList([])
            return
        }

        const q = query(collection(db, 'collectionHistory'), where('owner', '==', clientId), where('current', '==', true))
        getDocs(q).then((querySnapshot) => {
            const queries = []
            querySnapshot.forEach((docu) => {
                const collectionHistoryData = { ...docu.data() }
                const whereArray = ['item_id', '==', collectionHistoryData.sku]
                queries.push(getCollectionList({ whereArray }).then((results) => results.list))
            })

            Promise.all(queries).then(results => {
                if (!isCancelled) {
                    setList(results.flat())
                }
            })
        })

        return () => {
            isCancelled = true
        }
    }, [clientId])

    const appendItemData = async (profileId) => {
        //Do Nothing
    }

    return (
        <>
            <Box>
                <Heading variant="h4" as="h4">Owned Collection Items</Heading>
            </Box>
            {
                list.length ? 
                    <Box pt='xs' sx={{
                        display: 'grid',
                        gridGap: 'md',
                        gridTemplateColumns: ['1fr', '1fr', '1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)'],
                    }}>
                        {
                            Object.entries(list).map(([key, item]) => {
                                return <CollectionCard options={item} key={'vehicle_' + item.id} />
                            })
                        }
                    </Box>
                    :
                    <Text as='p' pt='xs'>This client does not currently own any vehicles on record.</Text>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        clientId: state.Page?.data?.id || '',
    }
}

export default connect(mapStateToProps)(CollectionItemsTab)
