import React, { forwardRef } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'
import styled from 'styled-components'
import TableInputError from '../tables/TableInputError'

const Container = styled(Flex)`
    position: relative;
    width:100%
    .contentEditableInput {
        position: absolute;
        left: 0;
        bottom: 4px;
    }
`
const Message = styled(Box)`
    position: absolute;
    left: -2px;
    bottom: -10px;
`

const ClientIdentityField = (props, ref) => {
    const { errors, errorMessage, name, ...rest } = props
    return (
        <Container>
            <Input ref={ref} name={name} {...rest} autoComplete="off"/>
            {(errors && errors[name]) && <Message><TableInputError message={errorMessage} /></Message>}
        </Container>
    )
}

export default forwardRef(ClientIdentityField)
