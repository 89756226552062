import React from 'react'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass/styled-components'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const RatingStarBox = styled(Box)`
    color: ${(props) => props.theme.colors.greyLight};
    font-size: ${(props) => props.theme.fontSizes.xxl};
    padding-right: 4px;
    &.active {
        color: ${(props) => props.theme.colors.yellowLight};
    }
`

const StarRatingSelect = ({ onInputChange, rating, noCursor }) => {
    return (
        <Flex sx={{ cursor: `${noCursor? 'normal': 'pointer'}` }}>
            <RatingStarBox onClick={() => onInputChange && onInputChange( rating === 1 ? 0 : 1)} color='greyMedium' className={rating >= 1 ? 'active' : ''}><FontAwesomeIcon icon={faStar} /></RatingStarBox>
            <RatingStarBox onClick={() => onInputChange && onInputChange(2)} className={rating >= 2 ? 'active' : ''}><FontAwesomeIcon icon={faStar} /></RatingStarBox>
            <RatingStarBox onClick={() => onInputChange && onInputChange(3)} className={rating >= 3 ? 'active' : ''}><FontAwesomeIcon icon={faStar} /></RatingStarBox>
        </Flex>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(StarRatingSelect)
