import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const CountrySelect = ({ dispatch, selectedValue, onChange }) => {
    const [selected, setSelected] = useState(selectedValue)
    const [countrySelect, setCountrySelect] = useState([])
    const [countries, setCountries] = useState(false)

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'countries'), orderBy('country', 'asc'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const data = { ...doc.data() }
                    data.id = doc.id
                    output.push(data)
                })
                setCountries(output)
            }
        })

        return () => {
            isCancelled = true
        }
    }, [])

    useEffect(() => {
        if (countries) {
            const countryOptions = []
            Object.entries(countries).map(([key, country]) => {
                countryOptions.push({ value: country.id, label: country.country })
                return true
            })
            setCountrySelect(countryOptions)
        }
    }, [countries])


    useEffect(() => {
        setSelected(selectedValue)
    }, [selectedValue])

    const onSelectChange = (event) => {
        onChange(event.value)
        // const country = Object.values(countrySelect).filter((item) => item.value === event.value)[0]
    }
    return (
        <Select
            name="template"
            options={countrySelect || []}
            value={countrySelect.find((obj) => obj.value === selected)}
            onChange={(e) => onSelectChange(e)}
        />
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(CountrySelect)
